import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import PatientCell from './PatientCell';
import ScheduleDatetimeCell from './ScheduleDatetimeCell';
import ServiceCell from './ServiceCell';
import StatusCell from './StatusCell';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import makeStyles from '@mui/styles/makeStyles';
import { sharedStyles } from '../../shared/styles';
import useDeadpollList from '@a2d24/care-at-work-core/Deadpoll/useDeadpollList';
import { useStores } from '@a2d24/care-at-work-state/stores';

const BookingsTable = ({ config, props, refresh }) => {
    const sharedClasses = sharedStyles();
    const tableRef = React.createRef();
    const [orderBy, setOrderBy] = React.useState(-1);
    const [orderDirection, setOrderDirection] = React.useState('');
    const [page, setPage] = React.useState(0);
    const { authStore } = useStores()
    const deadpollListId = props.deadpoll_list_id;
    const bookings = useDeadpollList(`${authStore.occhealthPracticeId}#${deadpollListId}`).map((obj)=>{return JSON.parse(JSON.stringify(obj))})
    const columns = [
        {
            field: 'fullName',
            title: 'Patient',
            customFilterAndSearch: (term, rowData) =>
                rowData.patient.name.toLowerCase().includes(term.toLowerCase()),
            customSort: (a, b) => (a.patient.name > b.patient.name ? -1 : 1),
            render: (rowData) => <PatientCell appointment={rowData} />,
        },
        {
            field: 'appointment_datetime',
            title: 'Day & Date',
            searchable: false,
            customSort: (a, b) => (new Date(a.scheduled_at) > new Date(b.scheduled_at) ? -1 : 1),
            render: (rowData) => <ScheduleDatetimeCell datetime={rowData.scheduled_at} />,
        },
        {
            field: 'service',
            title: 'Service',
            customFilterAndSearch: (term, rowData) => rowData.tariff_description.includes(term),
            customSort: (a, b) => (a.tariff_description > b.tariff_description ? -1 : 1),
            render: (rowData) => <ServiceCell service={rowData.tariff_description} />,
        },
        {
            field: 'status',
            title: 'Status',
            render: (rowData) => <StatusCell status={rowData.status} />,
        },
        {
            field: 'actions',
            title: 'Actions',
            render: (rowData) =>
                rowData.status === 'pending' ? (
                    <ActionsButton booking={rowData} refresh={refresh} />
                ) : null,
        },
    ];

    const updateOrderBy = (newOrderBy, newOrderDirection) => {
        setOrderBy(newOrderBy);
        setOrderDirection(newOrderDirection);
    };

    return (
        <MaterialTable
            tableRef={tableRef}
            data={bookings || []}
            columns={columns}
            onOrderCollectionChange={updateOrderBy}
            onChangePage={(page, pageSize) => setPage(page)}
            title={
                <div className={sharedClasses.subheading}>
                    {config.title || props.title || 'Appointments'}
                </div>
            }
            options={{
                headerStyle: {
                    color: '#999999',
                    fontSize: '11px',
                    fontWeight: 600,
                    lineHeight: '15px',
                },
                actionsColumnIndex: -1,
                minBodyHeight: '100%',
                searchFieldStyle: { marginRight: '48px' },
            }}
        />
    );
};

const ActionsButton = ({ booking, refresh }) => {
    const classes = useStyles();

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRegister = () => {
        handleClose();
        navigate(`/appointments/${booking.booking_id}`);
    };

    const handleNoShow = () => {
        Recycle.rpcPostV2({
            controller: 'BookingController',
            method: 'update_booking_status',
            kwargs: { new_status: 'no_show', booking_id: booking.booking_id },
        }).then((res) => {
            refresh();
        });
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClick} className={classes.actions} size="large">
                <MoreHorizIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={handleRegister}>Arrive</MenuItem>
                <MenuItem onClick={handleNoShow}>No Show</MenuItem>
            </Menu>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: 0,
    },
    s: {
        marginRight: '24px',
    },
}));

export default BookingsTable;
