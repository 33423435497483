import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { sharedStyles } from '../../shared/styles';
import Button from '@mui/material/Button';
// import Recycle from '@a2d24/care-at-work-core/Recycle';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import SignOffBackDrop from './SignOffBackDrop';
const API_URL = process.env.REACT_APP_API_ENDPOINT;

const Signoff = ({ config, props: { consult_id, consult_status, is_quick_signoff } }) => {
    const sharedClasses = sharedStyles();
    const classes = useStyles();
    const navigate = useNavigate();
    const [notes, setNotes] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const states = ['Processing results', 'Evaluating fitness', 'Evaluating co-morbidity', 'Generating reports', 'Signing off consult'];
    const [signoffProgressIndex, setSignoffProgressIndex] = useState(-1);

    const { enqueueSnackbar } = useSnackbar();

    const getSignOffProgress = (idx)=>{
        if(idx < 0){
            return "Processing";
        }
        else{
            console.log(idx)
            return states[idx];
        }
    }
    React.useEffect(() => {
        if (consult_status && consult_status === 'completed') {
            navigate(`/consults/${consult_id}/signoffcomplete`);
        }
    }, [consult_id, consult_status, navigate]);

    React.useEffect(()=>{
        if(submitting){
            const tempIndex = signoffProgressIndex + 1;
            setSignoffProgressIndex(tempIndex);
        }
    }, [submitting])

    React.useEffect(()=>{
        if(submitting && signoffProgressIndex<states.length -1) {
            console.log(signoffProgressIndex);
            setTimeout(() => {
                const tempIndex = signoffProgressIndex + 1;
                setSignoffProgressIndex(tempIndex);
            }, 1000)
        }
    }, [signoffProgressIndex])

    const quick_signoff = () => {
        setSubmitting(true);
        axios.post(`${API_URL}/ConsultController/quick_signoff`, {
            consult_id: consult_id,
            notes: notes
        })
            .then((res) => {
                setSubmitting(false);
                enqueueSnackbar('Quick Sign-off Complete ', { variant: 'success' });
                navigate(`/consults/${consult_id}/signoffcomplete`)
            })
            .catch((err) => {
                setSubmitting(false);
                enqueueSnackbar('Failed to request quick sign off', { variant: 'error' });
            });
    };


    const dsp_signoff = () => {
        setSubmitting(true);
        axios.post(`${API_URL}/ConsultController/dsp_signoff`, {
            consult_id: consult_id,
            notes: notes
        })
            .then((res) => {
                setSubmitting(false);
                navigate(`/consults/${consult_id}/signoffcomplete`);
                enqueueSnackbar('OMP Sign-off Complete', { variant: 'success' });
            })
            .catch((err) => {
                setSubmitting(false);
                enqueueSnackbar('OMP Sign-off was Unsuccessful', { variant: 'error' });
            });
    }

    const signoff = () => {
        if(is_quick_signoff){
            quick_signoff();
        }
        else{
            dsp_signoff();
        }
    }
    return (
        <Paper className={classes.container}>
            <div className={sharedClasses.heading}>Sign Off Approval</div>
            <TextField
                autoFocus
                onChange={(e) => setNotes(e.target.value)}
                label="Notes"
                multiline
                fullWidth
                value={notes}
            />
            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={signoff}
                disabled={submitting}
            >
                confirm
            </Button>
            <SignOffBackDrop open={submitting} text={getSignOffProgress(signoffProgressIndex)}/>
        </Paper>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
    },
    button: {
        justifySelf: 'end',
    },
    backDrop: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        color: theme.palette.primary.light

    }
}));

export default Signoff;
